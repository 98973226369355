<template>
  <div class="internal-invoice-listing">
    <v-layout class="my-2">
      <v-flex md8>
        <div class="text-h5 color-custom-blue me-2">Payment</div>
      </v-flex>
      <v-flex md4>
        <template v-if="isDetailMilestone">
          <div class="text-end">
            <v-btn
              @click="openInvoiceDialog"
              color="blue darken-4 mr-4 white--text"
              class="ml-4"
              tile
              depressed
            >
              <v-icon>mdi-plus</v-icon>Create</v-btn
            >
          </div>
        </template>
        <template v-else>
          <div class="d-flex align-center justify-space-between">
            <v-autocomplete
              dense
              color="cyan"
              filled
              id="milestone"
              :items="milestoneList"
              v-model="milestone"
              placeholder="Select Milestone"
              solo
              flat
              item-color="cyan"
              item-text="name"
              item-value="id"
              hide-details
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="'No Milestone Found.'"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <DateRangePicker
              placeholder="Data Range"
              v-model="dates"
              v-on:update:model-value="getHistories()"
              v-on:click:clear="clearFilter('dates')"
              class="mx-2"
            ></DateRangePicker>
            <v-btn
              color="blue darken-4 white--text"
              @click="initInvoice()"
              tile
              depressed
            >
              Search</v-btn
            >
          </div>
        </template>
      </v-flex>
    </v-layout>
    <ListingTable :columnCount="5" :rowData="rowData2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th width="10%" class="simple-table-th">Invoice No</th>
            <th class="simple-table-th">Title</th>
            <th width="20%" class="simple-table-th">Amount</th>
            <th width="20%" class="simple-table-th">Invoice Date</th>
            <th width="10%" class="simple-table-th">Status</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData2.length">
            <!-- v-on:click="routeToInvoice(row)" -->
            <tr
              v-for="(row, index) in rowData2"
              :key="index"
              class="alternate-listing-row"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
                <v-tooltip
                  v-if="row.recurring_cycles > 0"
                  top
                  content-class="custom-top-tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10 ml-1"
                      color="chip-custom-blue"
                      label
                      outlined
                    >
                      <span style="color: red">{{ row.recurring_cycles }}</span>
                      /
                      <span style="color: green">{{
                        row.recurring_count
                      }}</span>
                    </v-chip>
                  </template>
                  <span>No of Recurring / Total Schedule</span>
                </v-tooltip>
              </td>
              <td class="simple-table-td">
                <template v-if="row.title">
                  {{ row.title }}
                </template>
                <template v-else>
                  <em class="text-muted">no title</em></template
                >
              </td>

              <td class="simple-table-td">
                <div class="invoice-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Raised: </b>
                    <label class="m-0 red--text">{{
                      $accountingJS.formatMoney(row.collected_amount)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Paid: </b>
                    <label class="m-0 green--text">{{
                      $accountingJS.formatMoney(row.paid_amount)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Balance: </b>
                    <label class="m-0 orange--text">{{
                      $accountingJS.formatMoney(row.balance)
                    }}</label>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="invoice-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <template v-if="row.invoice_date">{{
                      formatDate(row.invoice_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No invoice Date</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <v-chip label outlined color="green">Paid</v-chip>
                <CustomStatus
                  v-if="false"
                  small
                  :status="row.status_value"
                  endpoint="invoice/status"
                ></CustomStatus>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="5">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no invoice at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="invoiceLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
    <Invoice
      v-if="invoice_dialog"
      :reassign-schedule-dialog="invoice_dialog"
      v-on:close="invoice_dialog = false"
    ></Invoice>
  </div>
</template>
  
  <script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import DateRangePicker from "@/view/components/DateRangePicker";
import Invoice from "@/view/pages/partials/Milestone-Invoice.vue";
export default {
  mixins: [CommonMixin],
  data() {
    return {
      invoiceLoading: false,
      invoice_dialog: false,
      rowData2: [
        {
          id: 1,
          uuid: "3ffddc62-e244-474d-8c60-f4368c18df7d",
          barcode: "INV0009",
          title: null,
          collected_amount: 600,
          status: 1,
          proforma: 22,
          remark: "0",
          date: null,
          added_at: "2024-10-04 13:01:07",
          amount: 600,
          proforma_barcode: "PE30",
          received_amounts_in_payments: 600,
          status_value: "paid",
          created_at: "3 weeks ago",
          paid_amount: 600,
          balance: 0,
          date_formatted: null,
          added_at_formatted: "04/10/2024 01:01 PM",
          updated_at_formatted: null,
          modified_at: null,
        },
      ],
      dates: [],
      milestone: null,
      milestoneList: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    isDetailMilestone: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.$nextTick(() => {
        this.getInvoices();
      });
    },
  },
  methods: {
    openInvoiceDialog() {
      this.invoice_dialog = true;
    },
    routeToDetailLink(id, type) {
      this.$router.push({
        name: type,
        params: { id },
      });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getInvoices();
    },
    getInvoices() {
      const data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        type: this.type,
        type_id: this.typeId,
        dates: this.dates,
      };

      if (this.invoiceLoading) {
        return false;
      }

      this.invoiceLoading = true;

      this.$store
        .dispatch(QUERY, { url: "invoice", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.invoiceLoading = false;
        });
    },
    routeToInvoice(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("invoice.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  mounted() {
    this.getInvoices();
  },
  components: {
    Barcode,
    CustomStatus,
    ListingFooter,
    ListingTable,
    DateRangePicker,
    Invoice,
  },
};
</script>
<style scoped>
.internal-invoice-listing
  .simple-table.custom-table-height
  .v-data-table__wrapper {
  height: calc(100vh - 350px) !important;
}
</style>
  
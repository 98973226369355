<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    <v-container class="pr-0">
      <v-layout>
        <v-flex class="pt-0 justify-start d-flex align-center margin-auto pb-2"
          ><div class="text-h5 color-custom-blue me-2">Proforma</div>
          <template v-if="isMilestoneDetail">
            <v-chip class="mx-1" outlined label color="orange">
              <span class="text-h6">Total Proforma: </span></v-chip
            >
            <v-chip class="mx-1" outlined label color="blue">
              <span class="text-h6">Total Pending: 10</span></v-chip
            >
            <v-chip class="mx-1" outlined label color="green">
              <span class="text-h6">Total Accepted: 0</span></v-chip
            >
            <v-chip class="mx-1" outlined label color="cyan">
              <span class="text-h6">Total Invoice: 0</span></v-chip
            >
          </template>
        </v-flex>
        <v-flex class="d-flex justify-end">
          <template v-if="isMilestoneDetail">
            <v-btn
              depressed
              class="custom-bold-button white--text"
              color="blue darken-4"
              tile
              :disabled="pageLoading"
              @click="proforma_dialog = true"
            >
              Generate Proforma Without Deliverable
            </v-btn>
          </template>
          <template v-else>
            <div class="mx-2 my-2">
              <DateRangePicker
                placeholder="Data Range"
                v-model="dates"
                v-on:update:model-value="getHistories()"
                v-on:click:clear="clearFilter('dates')"
              ></DateRangePicker>
            </div>
            <div class="mr-2 my-2">
              <v-text-field
                v-model.trim="filter.search"
                v-on:click:clear="clearFilter('search')"
                clearable
                dense
                filled
                flat
                placeholder="Search"
                hide-details
                solo
                :disabled="pageLoading"
                color="cyan"
                @keydown.enter="getHistories"
                @keydown.tab="getHistories"
              ></v-text-field>
            </div>
            <div class="ml-2 my-2">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    class="custom-bold-button white--text"
                    color="cyan"
                    tile
                    :disabled="pageLoading"
                    v-on:click="getHistories"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Search</span>
              </v-tooltip>
            </div>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
    <ListingTable
      :columnCount="6"
      :dataLoading="dataLoading"
      :rowData="proformasData"
    >
      <template v-slot:thead>
        <thead style="position: sticky; top: 0; z-index: 9">
          <tr>
            <th class="simple-table-th" width="5%">#</th>
            <th class="simple-table-th" width="10%">Proforma No</th>
            <th class="simple-table-th" width="20%">Title</th>
            <th class="simple-table-th" width="15%">Proforma Amount</th>
            <th class="simple-table-th" width="20%">Invoice Amount</th>
            <th v-if="isMilestoneDetail" class="simple-table-th" width="20%">
              Actions
            </th>
            <th class="simple-table-th" width="10%">Status</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="proformasData.length > 0">
            <tr :key="index" v-for="(data, index) in proformasData">
              <td class="simple-table-td" width="5%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.id">
                    {{ data.id }}
                  </template>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <Barcode :barcode="data.barcode"></Barcode>
              </td>
              <td class="simple-table-td" width="20%">
                <div class="project-listing-dates">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <template v-if="data && data.title">
                      {{ data.title }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">No title</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="15%">
                <div class="invoice-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Raised: </b>
                    <label class="m-0 red--text">{{
                      $accountingJS.formatMoney(data.amount)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Accepted: </b>
                    <label class="m-0 green--text">{{
                      $accountingJS.formatMoney(data.approved_amount)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Balance: </b>
                    <label class="m-0 orange--text">{{
                      $accountingJS.formatMoney(data.invoice_balance)
                    }}</label>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="20%">
                <div class="invoice-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Raised: </b>
                    <label class="m-0 red--text">{{
                      $accountingJS.formatMoney(data.invoice_raised_amount)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Paid: </b>
                    <label class="m-0 green--text">{{
                      $accountingJS.formatMoney(data.invoices_collected_amount)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Balance: </b>
                    <label class="m-0 orange--text">{{
                      $accountingJS.formatMoney(data.invoice_balance)
                    }}</label>
                  </p>
                </div>
              </td>
              <td v-if="isMilestoneDetail" class="simple-table-td" width="20%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        class="mr-3 custom-disabled"
                        @click="confirmationDialog = true"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Approve Proforma</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="blue darken-4"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        class="mr-3 custom-disabled"
                        @click="proforma_dialog = true"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Update Proforma</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="orange"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        class="mr-3 custom-disabled"
                        @click="rejectDialog = true"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Reject Proforma</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="red"
                        class="mr-3 custom-disabled-del"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        @click="deleteDialog = true"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Proforma</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        fab
                        dark
                        x-small
                        color="cyan"
                        class="mr-3 custom-disabled-del"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        @click="invoice_dialog = true"
                      >
                        <v-icon>mdi-receipt</v-icon>
                      </v-btn>
                    </template>
                    <span>Generate Invoice</span>
                  </v-tooltip>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.status_value">
                    <v-chip
                      class="text-uppercase"
                      small
                      label
                      outlined
                      color="amber darken-4 "
                      >{{ data.status_value }}</v-chip
                    >
                  </template>
                </p>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no Proforma at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <Invoice
      v-if="invoice_dialog"
      :reassign-schedule-dialog="invoice_dialog"
      v-on:close="invoice_dialog = false"
    ></Invoice>

    <ProformaCreate
      v-if="proforma_dialog"
      :reassign-schedule-dialog="proforma_dialog"
      v-on:close="proforma_dialog = false"
    ></ProformaCreate>
    <DeleteDialog :delete-dialog="deleteDialog">
      <template v-slot:title> Delete Proforma</template>
      <template v-slot:text>
        <div class="">
          <h3 class="font-size-16 font-weight-500 delete-text">
            Deleting Proforma <span class="red--text">#PE55</span> is
            irreversible and will also delete all associated records.
          </h3>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Are you sure about deleting it?
          </h3>
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteDialog = false"
          >No! Close</v-btn
        >
        <v-btn
          depressed
          :disabled="dataLoading"
          :loading="dataLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <DeleteDialog :delete-dialog="rejectDialog">
      <template v-slot:title> Reject Proforma</template>
      <template v-slot:text>
        <div class="">
          <h3 class="font-size-16 font-weight-500 red--text">
            Are you sure to reject Proforma
          </h3>
          <TextAreaField
            auto-grow
            dense
            filled
            color="cyan"
            placeholder="Enter Remark"
            solo
            flat
            row-height="20"
            counter="250"
            v-model="remark"
          />
        </div>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="rejectDialog = false"
          >No! Close</v-btn
        >
        <v-btn
          depressed
          :disabled="dataLoading"
          :loading="dataLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Reject</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :commonDialog="confirmationDialog">
      <template v-slot:title> Approve Proforma </template>
      <template v-slot:body>
        <v-row>
          <v-col md="12" class="pt-0 d-flex align-center">
            <v-icon class="me-4" size="50" color="green"
              >mdi-checkbox-marked-circle</v-icon
            >
            <h2 class="font-weight-600 delete-text text-center">
              Are you sure to approve Proforma
            </h2>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="confirmationDialog = false"
        >
          No, Close
        </v-btn>
        <v-btn
          depressed
          :disabled="dataLoading"
          :loading="dataLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="updateStatus(updateStatusValue, updateStatusUuid)"
        >
          Yes! Approve
        </v-btn>
      </template>
    </Dialog>
  </v-container>

  <!--end::Ticket Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import DateRangePicker from "@/view/components/DateRangePicker";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Invoice from "@/view/pages/partials/Milestone-Invoice.vue";
import ProformaCreate from "@/view/pages/partials/Proforma-Create.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "timesheet-internal-list",
  data() {
    return {
      dates: [],
      filter: {
        search: null,
      },
      remark: null,
      invoice_dialog: false,
      proforma_dialog: false,
      pageLoading: false,
      deleteDialog: false,
      rejectDialog: false,
      confirmationDialog: false,
      dataLoading: null,
      proformasData: [
        {
          id: 1,
          uuid: "959ddcb8-e6af-446e-8b2c-a9f37446e533",
          barcode: "PE57",
          title: null,
          amount: 10000,
          approved_amount: 10000,
          added_at: "2024-10-18 11:14:36",
          updated_at: null,
          status: 1,
          invoices_amounts_detail:
            '{"amount": null, "paid_amount": null, "collected_amount": null}',
          created_at: "3 hours ago",
          status_value: "pending",
          invoice_raised_amount: 0,
          invoices_collected_amount: 0,
          invoice_balance: 0,
          balance: 0,
          performa_attachments: [],
          certificate: [],
        },
        // {
        //   id: 2,
        //   uuid: "8a4f4320-3196-41e2-8646-c4f83e0e8dd3",
        //   barcode: "PE56",
        //   title: "tt",
        //   amount: 200,
        //   approved_amount: 200,
        //   added_at: "2024-10-07 16:09:04",
        //   updated_at: null,
        //   status: 1,
        //   invoices_amounts_detail:
        //     '{"amount": null, "paid_amount": null, "collected_amount": null}',
        //   created_at: "1 week ago",
        //   status_value: "pending",
        //   invoice_raised_amount: 0,
        //   invoices_collected_amount: 0,
        //   invoice_balance: 0,
        //   balance: 0,
        //   performa_attachments: [],
        //   certificate: [],
        // },
      ],
    };
  },
  props: {
    isMilestoneDetail: {
      type: Boolean,
      required: false,
    },
    // detail: {
    //   type: Object,
    //   required: true,
    //   default: () => {
    //     return new Object();
    //   },
    // },
    // type: {
    //   type: String,
    //   required: true,
    //   default: "project",
    // },
  },
  components: {
    Barcode,
    ListingTable,
    DateRangePicker,
    DeleteDialog,
    Dialog,
    Invoice,
    ProformaCreate,
    TextAreaField,
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getHistories();
    },
    getHistories() {
      const _this = this;

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      _this.pageLoading = true;
      _this.histories = [];
      _this.$store
        .dispatch(QUERY, {
          url: "history",
          data: {
            type: _this.type,
            type_id: _this.type_id,
            per_page: _this.per_page,
            current_page: _this.current_page,
            search: _this.filter.search,
            start_date: _this.dates[0] || null,
            end_date: _this.dates[1] || null,
            is_current_user: _this.isCurrentUser,
          },
        })
        .then(({ data }) => {
          _this.offset = data.offset;
          _this.histories = data.rows;
          _this.total_pages = Math.ceil(data.total_rows / data.per_page);
          _this.current_page = data.current_page;
          _this.total_rows = data.total_rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {},
};
</script>

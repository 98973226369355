<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    <v-layout>
      <v-flex class="pt-0 pb-2 d-flex margin-auto">
        <div class="text-h5 color-custom-blue">Deliverable Line Items</div>
      </v-flex>
    </v-layout>
    <ListingTable
      :columnCount="12"
      :dataLoading="dataLoading"
      :rowData="DeliverableLineItems"
    >
      <template v-slot:thead>
        <thead style="position: sticky; top: 0; z-index: 5">
          <tr>
            <th class="simple-table-th" width="10%">Quotation #</th>
            <th class="simple-table-th" width="10%">Name</th>
            <th class="simple-table-th" width="10%">Brand</th>
            <th class="simple-table-th" width="10%">Description</th>
            <th class="simple-table-th" width="10%">Price</th>
            <th class="simple-table-th" width="10%">UOM</th>
            <th class="simple-table-th" width="5%">Quotation QTY</th>
            <th class="simple-table-th" width="10%">Total Deliverable</th>
            <th class="simple-table-th" width="5%">Quotation Qty GAP</th>
            <th class="simple-table-th" width="5%">Total Delivered</th>
            <th class="simple-table-th" width="10%">Rejected</th>
            <th class="simple-table-th" width="5%">GAP (Delivered Balance)</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="DeliverableLineItems.length > 0">
            <tr :key="index" v-for="(data, index) in DeliverableLineItems">
              <td class="simple-table-td" width="10%">
                <Barcode :barcode="data.barcode"></Barcode>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.name">
                    {{ data.name }}
                  </template>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.brand">
                    {{ data.brand }}
                  </template>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.description">
                    {{ data.description }}
                  </template>
                  <template v-else
                    ><em class="text--secondary">No description</em></template
                  >
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p
                  class="m-0 custom-nowrap-ellipsis"
                  style="white-space: pre-wrap !important"
                >
                  <template v-if="data && data.line_item_total">
                    <span class="font-size-16 font-weight-600">{{
                      formatMoney(data.line_item_total)
                    }}</span>
                  </template>
                  <template v-else
                    ><em class="text--secondary">No price</em></template
                  >
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <div class="project-listing-dates">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <template v-if="data && data.line_item_uom">
                      {{ data.line_item_uom }}
                    </template>
                  </p>
                </div>
              </td>
              <td
                class="simple-table-td"
                width="10%"
                style="background: aliceblue"
              >
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="blue--text" v-on="on" v-bind="attrs">
                        {{ data.line_item_quantity }}
                      </span>
                    </template>
                    <span>Quotation QTY</span>
                  </v-tooltip>
                </p>
              </td>
              <td
                class="simple-table-td"
                width="10%"
                style="background: #e0f7f7"
              >
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="cyan--text" v-on="on" v-bind="attrs">
                        {{ data.total_qty_to_be_delivered }}
                      </span>
                    </template>
                    <span>Total Deliverable Created </span>
                  </v-tooltip>
                </p>
              </td>
              <td
                class="simple-table-td"
                width="10%"
                style="background: aliceblue"
              >
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs">
                        {{ data.all_milestones_qty_gap }}
                      </span>
                    </template>
                    <span>All Milestone Qty GAP</span>
                  </v-tooltip>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="green--text" v-on="on" v-bind="attrs">
                        {{ data.total_delivered_qty }}
                      </span>
                    </template>
                    <span>Total Delivered</span>
                  </v-tooltip>
                </p>
              </td>

              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.total_returned_qty">
                    <span class="red--text">{{ data.total_returned_qty }}</span>
                  </template>
                </p>
              </td>
              <td class="simple-table-td" width="10%">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="orange--text" v-on="on" v-bind="attrs">
                        {{ data.gap }}
                      </span>
                    </template>
                    <span>Delivered Balance</span>
                  </v-tooltip>
                </p>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="12">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no line items at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Ticket Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "timesheet-internal-list",
  data() {
    return {
      dataLoading: false,
      DeliverableLineItems: [
        {
          id: 1,
          barcode: "QT0056",
          name: "LED STRIPS",
          brand: "Lite",
          description: "",
          line_item: 10255,
          line_item_uom: "unit",
          line_item_quantity: 210,
          line_item_total: 15000,
          line_item_product_type: "inventory",
          all_milestones_total_qty_to_be_delivered: 0,
          all_milestones_qty_gap: 210,
          total_qty_to_be_delivered: 0,
          total_delivered_qty: 0,
          total_returned_qty: 0,
          gap: 0,
        },
        {
          id: 2,
          barcode: "QT0057",
          name: "RURE IQ LED STRIPS",
          brand: "JRLite",
          description: "",
          line_item: 10255,
          line_item_uom: "unit",
          line_item_quantity: 100,
          line_item_total: 10000,
          line_item_product_type: "inventory",

          all_milestones_total_qty_to_be_delivered: 0,
          all_milestones_qty_gap: 100,
          total_qty_to_be_delivered: 0,
          total_delivered_qty: 0,
          total_returned_qty: 0,
          gap: 0,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "project",
    },
  },
  // watch: {
  //   detail: {
  //     deep: true,
  //     immediate: true,
  //     handler(param) {
  //       this.parent = this.lodash.toSafeInteger(param.id);
  //       if (this.parent > 0) {
  //         this.getAllMilestones();
  //       }
  //       /*  this.setProjectBalance(); */
  //     },
  //   },
  // },
  components: {
    Barcode,
    ListingTable,
  },
  methods: {},
  mounted() {},
};
</script>
